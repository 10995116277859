import React from 'react'
import styled from 'styled-components'

import { Colors, Type, Breakpoints } from '@/styles'
import { Width } from '@/components'
import LocalizedLink from '../LocalizedLink/LocalizedLink'
import Socials from '../Socials/Socials'
import { Logo, Colors as ATColors } from '@all-turtles/components'
import Footer from '@/components/Icons/Footer'

export const Background = styled(Footer)`
  position: absolute;
  bottom: -110px;
  width: 2560px;
  height: 700px;
  left: 50%;
  margin-left: -1280px;

  @media (min-width: ${Breakpoints.tablet}) {
    bottom: 0;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    bottom: 0;
  }
`

export const FooterComponent = styled.footer`
  display: flex;
  align-items: flex-end;
  padding-bottom: 23px;
  box-sizing: border-box;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 700px;
  overflow: hidden;
  margin-top: -404px;

  @media (min-width: ${Breakpoints.tablet}) {
    padding-bottom: 63px;
    margin-top: 0;
  }
`

export const StyledWidth = styled(Width)`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`

export const Copy = styled.div``

export const CopyAndSocials = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${Breakpoints.desktop}) {
    flex-direction: row;
  }
`

export const Partner = styled.div`
  ${Type.subhead20};
  color: ${Colors.white};
  margin-bottom: 8px;
`

export const Description = styled.div`
  ${Type.body10};
  color: ${Colors.white};
  max-width: 295px;
  margin-bottom: 24px;
  padding-right: 55px;

  @media (min-width: ${Breakpoints.tablet}) {
    max-width: 444px;
  }

  a {
    color: ${Colors.white};
    opacity: 1;
    transition: all 200ms ease;
    border-bottom: 1px solid ${Colors.white};

    &:hover {
      color: ${Colors.white};
      opacity: 0.75;
    }
  }
`

export const CopyrightAndLegal = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledCopyright = styled.p`
  ${Type.body40};
  color: ${Colors.white};
  opacity: 0.5;
  margin-top: 12px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.body40};
  color: ${Colors.white};
  opacity: 1;
  transition: all 200ms ease;
  margin-left: 12px;
  border-bottom: 1px solid ${Colors.white};

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    &:first-child {
      margin-left: 12px;
    }
  }

  &:hover {
    color: ${Colors.white};
    opacity: 0.75;
  }
`

export const StyledLogo = styled(Logo)`
  margin-bottom: 37px;
  width: 44px;
  height: 33px;
  align-self: flex-end;
  flex-shrink: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 131px;
    width: 68px;
    height: 52px;
  }
`

export const DesktopSocials = styled(Socials)`
  display: none;

  @media (min-width: ${Breakpoints.desktop}) {
    display: flex;
  }
`

export const MobileSocials = styled(Socials)`
  display: flex;

  @media (min-width: ${Breakpoints.desktop}) {
    display: none;
  }
`

export const CareersLink = styled.a`
  color: ${Colors.white};
  border-bottom: 1px solid currentColor;

  &:hover {
    color: ${ATColors.brand};
  }
`
